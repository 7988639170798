.textAnimation {
    animation: fadeInOut 1s ease-in-out infinite alternate;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  